import { Card } from "@mui/material";
import React from "react";
import styles from '../../styles/Home.module.css';

const FeedbackCard = ({ studentName, feedbackText, studentImage }) => {
    return (
        <Card
            sx={{
                maxWidth: '100%',  // Allow the card to resize dynamically
                minHeight: 311,  // Keep a minimum height
                padding: '30px',
                margin: "20px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                display: 'flex',
                flexDirection: 'column',  // Align text vertically
                justifyContent: 'flex-start',
                overflow: 'hidden',  // Prevent overflow
            }}
        >
            <div className={styles.Rectangle19span}>
                ‘‘
            </div>
            <div className={styles.stuentFeedback}>
                " {feedbackText} "
                <br />
                <span className={styles.textStudentName}>{`-[ ${studentName} ]`}</span>
            </div>
        </Card>
    );
};

export default FeedbackCard;

import axios from "axios";
import { configUrl } from "../config";

const TargetAudienceOfTest = async (courseid, providerId, contentCuratorIds) => {
    axios.defaults.headers.common = {
        providerId: providerId,
        contentProviderIds: contentCuratorIds
    };
    let response = await axios.get(
        `${configUrl.AssessmentServer}/api/Courses/getTargetAudienceOfTest/${courseid}`
    );
    let data = response.data["$values"];
    return data;
};

export default {
    TargetAudienceOfTest: TargetAudienceOfTest,
}
import axios from "axios";
import { useQuery } from "react-query";
import { configUrl } from "../config";

export default function useGetAssignedTests(userId, testStatus, filter, targetAudience, searchValue) {

  return useQuery(["assignedTests", userId], async () => {

    console.log("userId", userId, filter);
    let searchVals;
    if (!userId) {
      return;
    }
    if (searchValue == '') {
      searchVals = null
    } else {
      searchVals = searchValue
    }

    let response = await axios.get(
      filter == true ?
        `${configUrl.AssessmentServer}/api/Tests/getAssignedTests/${userId}/${testStatus}/${targetAudience}?filter=true&testName=${searchVals}`
        : `${configUrl.AssessmentServer}/api/Tests/getAssignedTests/${userId}/${testStatus}/${targetAudience}?testName=${searchVals}`
    );

    return response.data["$values"];
  },
    {
      staleTime: 1000 * 60 * 5,
    });
}


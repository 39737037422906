import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import HomeScreen from "./Components/HomeScreen";
import LetsStart from "./Components/LetsStart";
import ParentDetails from "./Components/ParentDetails";
import PersonalDetails from "./Components/PersonalDetails";
import SignInForm from "./Components/SignInForm";
import SignUpForm from "./Components/SignupForm";
import StudentDashboard from "./Dashboard/StudentDashboard";
import ProtectedRoute from "./ProtectedRoute";
import { configUrl } from "./config";
import { LoginContext } from "./contexts/LoginContext";

/* New Code Starts here */

import { CssBaseline, ThemeProvider, useTheme } from "@mui/material";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./Components/ForgotPassword";
import NewSignIn from "./Components/MedineerComponents/NewSignIn";
import NewSignUp from "./Components/MedineerComponents/NewSignUpForm";
import PageUnderDevelopment from "./Components/MedineerComponents/PageUnderDevelopment";
import Home from "./SplashScreens/Home";
import TestSeriesDetails from "./SplashScreens/TestSeriesDetails";
import app from "./firebase";
import { createAppTheme } from "./theme";


/* Ends here*/
function App() {
  const [providerId, setProviderId] = React.useState(0);
  const [providerKey, setProviderKey] = React.useState("");
  const [UserId, setUserId] = React.useState();
  const [providerName, setProviderName] = React.useState("");
  const [UserName, setUserName] = React.useState("");
  const [emailC, setEmailC] = React.useState("");
  const [userDetail, setUserDetail] = React.useState([]);
  const [educationDetails, setEducationDetails] = React.useState([]);
  const [siblingDetail, setSiblingDetail] = React.useState([]);
  const [qIdHint, setQIdHint] = React.useState([]);
  const [status, setStatus] = React.useState("ALL");
  const [testSeriesStatus, setTestSeriesStatus] = React.useState("ALL");
  const [testSeriesObj, setTestSeriesObj] = React.useState([]);
  const [testSeriesCardStatus, setTestSeriesCardStatus] = React.useState("");
  const [topicId, setTopicId] = React.useState();
  const [alertFlag, setAlertFlag] = React.useState(false);
  const [courseId, setCourseId] = React.useState();
  const [assessment, setAssessmet] = React.useState(true);
  const [showToC, setShowTOC] = React.useState(false);
  const [pageKey, setPageKey] = React.useState(0);
  const [isDrawerNavigation, setIsDrawerNavigation] = React.useState(true);
  const [userPrivileges, setUserPrivileges] = React.useState();
  const [packageId, setPackageId] = React.useState();
  const [showClose, setShowModalClose] = React.useState(true);
  const [themeColor, setThemeColor] = React.useState('vividgreen'); // Default theme color
  const [contentCuratorIds, setContentCuratorIds] = React.useState([]);
  const [isDefaultSplash, setIsDefaultSplash] = React.useState(true);
  const [isParentLogin, setIsParentLogin] = React.useState(false);
  const client = new QueryClient();
  let config = JSON.parse(`{"api":"${configUrl.AssessmentServer}"}`);
  // console.log('local config',config)

  const messaging = getMessaging(app);
  const theme = useTheme();
  const activateMessages = async () => {
    // console.log('messaging',messaging);
    const token = await getToken(messaging, {
      vapidKey:
        "BFLHmJUchm5llz8UsotJ8ztrq30QWeATAIwEb-Oxl2lEjztAG1qYRWXxkccoQJfLGY2vVbYj0NuQHdUteFT0570",
    }).catch((error) => console.log("error generatin token"));

    if (token) console.log("token", token);
    if (!token) console.log("no token");
  };

  const getPushMessage = () => {
    console.log("console in getMessage");
    onMessage(messaging, (message) => {
      console.log("your message: ", message);
      toast(message.data.details);
      console.log("payload.data.url" + message.data.url);
      //window.location.replace("http://localhost:3000/"+message.data.url)
    });
  };
  //We only run once using react hook useEffect
  React.useEffect(() => {
    activateMessages();
    getPushMessage();
  }, []);
  const gradientBackgrounds = {
    // will add it later if req.
    // purple: 'linear-gradient(135deg, #5C4892, #DD2466)',  // Purple gradient
    // green: 'linear-gradient(135deg, #4CAF50, #FF9800)',   // Green gradient
    // blue: 'linear-gradient(135deg, #2196F3, #FFC107)',    // Blue gradient
    // teal: 'linear-gradient(135deg, #029c91, #272740)',    // Teal gradient
    deamblue: 'linear-gradient(176deg, rgb(18, 47, 117) 20%, rgb(41, 39, 147) 40%,rgb(18, 47, 117))',   //'linear-gradient(170deg, rgb(41, 39, 147) 60%, rgb(18, 53, 151) 90%)' // 'linear-gradient(50deg,rgb(18, 53, 151) 0%,rgb(151, 171, 255)  90%)'  //'linear-gradient(135deg, #97ABFF 10%, #123597 100%)',   //#2196F3, #FFC107)', // Dreamblue gradient
    vividorange: 'radial-gradient(circle, #DEC08E, #EDEBE8, #E8D7BB )',
  };
  // 

  useEffect(() => {
    let deferredPrompt;

    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      deferredPrompt = event;

      const installButton = document.getElementById('installButton');
      if (installButton) {
        installButton.style.display = 'block';

        installButton.addEventListener('click', () => {
          deferredPrompt.prompt();
          deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the install prompt');
            } else {
              console.log('User dismissed the install prompt');
            }
            deferredPrompt = null;
          });
        });
      }
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  // 
  return (
    <ThemeProvider theme={createAppTheme(themeColor)}>
      <CssBaseline />
      <div className="App" style={{
        // background: theme.palette.background.default
        background: gradientBackgrounds[themeColor], // Apply gradient based on themeColor
        minHeight: '100vh',
        transition: 'background 0.4s ease',
        overflowX: 'hidden'
      }}>

        <ToastContainer />
        <Router>
          <QueryClientProvider client={client}>
            <LoginContext.Provider
              value={{
                packageId,
                setPackageId,
                providerId,
                setProviderId,
                providerKey,
                setProviderKey,
                providerName,
                setProviderName,
                config,
                UserId,
                setUserId,
                setUserName,
                UserName,
                emailC,
                courseId,
                setCourseId,
                assessment,
                setAssessmet,
                isDrawerNavigation,
                setIsDrawerNavigation,
                testSeriesObj,
                setTestSeriesObj,
                testSeriesCardStatus,
                setTestSeriesCardStatus,
                topicId,
                setTopicId,
                alertFlag,
                setAlertFlag,
                showToC,
                setShowTOC,
                testSeriesStatus,
                setTestSeriesStatus,
                setEmailC,
                userDetail,
                setUserDetail,
                educationDetails,
                setEducationDetails,
                siblingDetail,
                setSiblingDetail,
                qIdHint,
                setQIdHint,
                pageKey,
                setPageKey,
                status,
                setStatus,
                userPrivileges,
                setUserPrivileges,
                showClose,
                setShowModalClose, themeColor,
                setThemeColor,
                contentCuratorIds,
                setContentCuratorIds,
                isDefaultSplash,
                setIsDefaultSplash,
                isParentLogin,
                setIsParentLogin
              }}
            >
              <Routes>
                <Route path="*" element={<PageUnderDevelopment />} />
                <Route element={<ProtectedRoute Component={Home} />} path="/"></Route>
                {/* <Route element={<Home />} path="/medineer"></Route> */}
                <Route element={<TestSeriesDetails />} path="/medineer/test-series-details"></Route>
                <Route element={<LetsStart />} path="/:id"></Route>
                <Route element={<NewSignIn />} path="/medineer/SignIn"></Route>
                <Route element={<NewSignUp />} path="/medineer/SignUp"></Route>
                <Route element={<SignInForm />} path="/signIn/*"></Route>
                <Route element={<SignUpForm />} path="/signup/:id"></Route>
                <Route element={<ForgotPassword />} path="/forgotPass/:userId/*"></Route>

                {/* <Route element={<SignUpForm />} path="/signup"></Route> */}
                <Route element={<ProtectedRoute Component={HomeScreen} />} path="/Home"></Route>
                <Route element={<ProtectedRoute Component={PersonalDetails} />} path="/Profileupdate"></Route>
                <Route element={<ProtectedRoute Component={ParentDetails} />} path="/Home/Parentdetails"></Route>
                <Route element={<ProtectedRoute Component={StudentDashboard} />} path="/Home/Dashboard"></Route>

              </Routes>
            </LoginContext.Provider>
          </QueryClientProvider>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;

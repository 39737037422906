import axios from "axios";
import { configUrl } from "../config";

const getCourseTestSchedule = async (courseId) => {
    console.log(courseId, 'kk')
    let response = await axios.get(
        `${configUrl.AssessmentServer}/api/Tests/getProviderTestSchedulingForCourse/${courseId}`
    );
    let data = response.data;


    return data;
};

export default {
    getCourseTestSchedule: getCourseTestSchedule,
}
import { CircularProgress } from '@material-ui/core';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Button, Divider, Grid, IconButton, Pagination, Typography, useTheme } from '@mui/material';
import { message } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import FeedbackCard from '../Components/MedineerComponents/FeedbackCards';
import { configUrl } from '../config';
import { LoginContext } from '../contexts/LoginContext';
import useGetCourseCount from '../courseHooks/useGetCourseCount';
import useGetAllObjective from '../Hooks/useGetAllObjective';
import getProviderContentCuratorIds from '../Hooks/useGetProviderContentCuratorIds';
import useGetTestsCount from '../Hooks/useGetTestsCount';
import styles from '../styles/Home.module.css';
import Header from './Header';
import TestSeriesCard from './TestSeriesCard';

const carouselImages = [
  {
    id: 1,
    imageUrl: "header1.jpg", // Adjust the path based on your setup
    title: "Slide One",
    description: "This is the description for slide one.",
  },
  {
    id: 2,
    imageUrl: "header1.jpg",
    title: "Slide Two",
    description: "This is the description for slide two.",
  },
  {
    id: 3,
    imageUrl: "header1.jpg",
    title: "Slide Three",
    description: "This is the description for slide three.",
  },
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
};
const testSeriesDataDetails = [
  { title: 'JEE Main Test Series 1 - 2025', testsCount: '10', duration: '3 Months', description: 'Description for Series 1', image: '/OIP4.jpeg', bulletPoints: ['School like discipline and homework', 'Daily Doubt Solving with teachers', 'Closest to actual exam, most relevant', 'Practiced by more than 1500+ IITians in 2024'], price: '₹ 990' },
  { title: 'JEE Main Test Series 2 - 2024', testsCount: '09', duration: '6 Months', description: 'Description for Series 2', image: '/online1.jpg', bulletPoints: ['All India Test Series with analysis', 'Most affordable', 'Prizes worth Rs 3 lakh', 'Practiced by more than 1500+ IITians in 2024', '1-1 Mentoring sessions for students'], price: '₹ 899' },
  { title: 'JEE Main Test Series 3 - 2025', testsCount: '14', duration: '5 Months', description: 'Description for Series 3', image: '/OIP3.jpeg', bulletPoints: ['All India Test Series with analysis', 'Maximum number of tests', 'Practiced by more than 1500+ IITians in 2024', '1-1 Mentoring sessions for students'], price: '₹ 796' },
  { title: 'JEE Main Test Series 4 - 2025', testsCount: '18', duration: '2 Months', description: 'Description for Series 4', image: '/online2.png', bulletPoints: ['School like discipline and homework', 'Prizes worth Rs 3 lakh', 'Daily Doubt Solving with teachers', 'Based on new pattern'], price: '₹ 693' },
  { title: 'JEE Main Test Series 5 - 2025', testsCount: '20', duration: '3 Months', description: 'Description for Series 4', image: '/OIP.jpg', bulletPoints: ['School like discipline and homework', 'Daily Doubt Solving with teachers', 'Practiced by more than 1500+ IITians in 2024', 'Based on new pattern'], price: '₹ 899' },
  { title: 'JEE Main Test Series 6 - 2025', testsCount: '50', duration: '7 Months', description: 'Description for Series 4', image: '/JEE1.png', bulletPoints: ['School like discipline and homework', 'Daily Doubt Solving with teachers', 'Practiced by more than 1500+ IITians in 2024', 'Based on new pattern'], price: '₹ 591' },
];

message.config({
  top: 95,
});
function Home() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(1); // active card index
  const [activeButtonIndex, setActiveButtonIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(3);
  const [totalPages, setTotalPages] = useState(1);
  const [testSeries, setTestSeries] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [objectiveFlag, setObjectiveFlag] = useState(null);

  const { contentCuratorIds, setContentCuratorIds } = useContext(LoginContext);

  const fetch = async () => {
    try {
      let ids;
      if (contentCuratorIds.length <= 0) {
        const data = await getProviderContentCuratorIds(configUrl.MediNeerProviderId);
        ids = data?.$values || [];
      }
      axios.defaults.headers.common = {
        providerId: configUrl.MediNeerProviderId,
        contentProviderIds: ids ?? contentCuratorIds
      };
      setContentCuratorIds(ids ?? contentCuratorIds);
    } catch (error) {
      console.log("error getting contentcuratorids");
    }
  };

  console.log("contenturatid", contentCuratorIds);

  const { data: testSeriesCount } = useGetCourseCount();

  const { data: testCount } = useGetTestsCount();

  const handleNavigation = (series, index) => {
    // navigate(route);
    if (objectiveFlag == series?.id) {
      setActiveButtonIndex(null);
      setObjectiveFlag(null);
    } else {
      setActiveButtonIndex(index);
      setObjectiveFlag(series?.id);
    }
  };


  const { data: objectiveResponse } = useGetAllObjective(
    "Objective",
    true,
    true,
    false
  );
  console.log("objectves", objectiveResponse);
  useEffect(() => {
    if (objectiveResponse && objectiveResponse?.length > 0) {
      setObjectives(objectiveResponse);
    }
  }, [objectiveResponse]);

  const fetchTests = async (page, objective) => {
    setLoading(true);
    console.log(contentCuratorIds, 'KKK1')
    // if (contentCuratorIds.length <= 0) {
    await fetch();
    // }
    try {
      const response = await axios.get(
        `${configUrl.AssessmentServer}/api/Courses`,
        {
          params: {
            pageNumber: page,
            pageSize: pageSize,
            contentFilter: 0,
            contentCuratorIds: null,
            objective: objective || null,
            isMedineerLogin: true
          },
        }
      );
      const fetchedTests = response?.data?.$values || [];
      const totalItems = (response?.data?.$values && response?.data?.$values?.length > 0 && response?.data?.$values[0]?.totalCount) || 0;
      setTestSeries(fetchedTests);
      setTotalPages(Math.ceil(totalItems / pageSize));
      console.log("toatalcount", Math.ceil(totalItems / pageSize), totalItems)
    } catch (error) {
      console.error("Error fetching test series:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (objectives && objectives?.length > 0) {
      setTestSeries([]); // Clear previous series
      fetchTests(1, objectiveFlag);
      if (pageNumber !== 1) {
        setPageNumber(1);
      }
    }
  }, [objectiveFlag]);


  useEffect(() => {
    if (objectiveFlag != null) {
      fetchTests(pageNumber, objectiveFlag);
    } else {
      fetchTests(pageNumber);
    }
  }, [pageNumber]);

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const scrollRef = useRef(null);

  const handleLearnMore = (series) => {
    navigate('/medineer/test-series-details', { state: { series } });
  };
  const onEnroll = (series) => {
    console.log("assignLicnesescountssecond", (series?.assingnedStudentsCount + 1) > series?.testSeriesStudentsCount);

    if (series?.assingnedStudentsCount && series?.testSeriesStudentsCount && series?.testSeriesStudentsCount > 0 && (series?.assingnedStudentsCount + 1) > series?.testSeriesStudentsCount) {
      message.error("Please contact the admin.");
    } else {
      navigate('/medineer/SignUp', { state: { series } });
    }
  }

  const scroll = (direction) => {
    if (scrollRef.current) {
      const container = scrollRef.current;
      const cardWidth = container.firstChild.offsetWidth; // Get card width dynamically
      const scrollAmount = cardWidth * 3; // Calculate scroll for 3 cards
      const maxScrollLeft = container.scrollWidth - container.clientWidth; // Max scrollable distance

      // Determine new scroll position
      const newScrollLeft = direction === 'left'
        ? Math.max(container.scrollLeft - scrollAmount, 0) // Ensure it doesn't scroll past the start
        : Math.min(container.scrollLeft + scrollAmount, maxScrollLeft); // Ensure it doesn't scroll past the end

      // Scroll the container
      container.scrollTo({ left: newScrollLeft, behavior: 'smooth' });

      // Update the active index
      const newIndex = activeIndex + (direction === 'left' ? -3 : 3); // Update by 3 cards
      const maxIndex = Math.ceil(testSeriesDataDetails.length / 3); // Max number of scrollable sections
      setActiveIndex(Math.max(1, Math.min(newIndex, maxIndex))); // Keep index within bounds
    }
  };
  const handleDotClick = (index) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = index * 400; // Adjust the scroll position through on crd width
      setActiveIndex(index + 1);
    }
  };
  const gradientBackgrounds = {
    deamblue: 'linear-gradient(176deg, rgb(18, 47, 117) 20%, rgb(41, 39, 147) 40%,rgb(18, 47, 117))',   //'linear-gradient(170deg, rgb(41, 39, 147) 60%, rgb(18, 53, 151) 90%)' // 'linear-gradient(50deg,rgb(18, 53, 151) 0%,rgb(151, 171, 255)  90%)'  //'linear-gradient(135deg, #97ABFF 10%, #123597 100%)',   //#2196F3, #FFC107)', // Dreamblue gradient
    vividorange: 'radial-gradient(circle, #DEC08E, #EDEBE8, #E8D7BB )',
  };
  const contentStyle = {
    height: '460px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

  return (
    <>
      <Header />
      <Box className={styles.defaultBackground}>
        {/* First section */}
        <Box
          className={styles.bannerofHeader}
          sx={{
            position: 'relative',
            // backgroundImage: `url('/header1.jpg')`, // Replace with your image URL
            // background: 'linear-gradient(to bottom, #b8dcee, #e9fde0)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            // height: '460px',
            color: '#fff', // Text color to be white for contrast
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            marginBottom: '8%'
          }}
        >
          <Grid container justifyContent={'center'} textAlign={'left'} paddingRight={'3%'}>
            <Grid item xs={12} sm={12} md={8} className={styles.productInfo}>
              <span className={styles.UnlockYourPotential}>
                Unlock Your Potential
              </span>
              <br />
              <span className={styles.subHeading}>
                Expert-Led Courses to Transform Your Future. Discover a world of knowledge.<br />
                Start your journey today. Empower your future with our courses to choose from.
              </span><br />
              <Button
                className={styles.Rectangle3}
                style={{ visibility: 'hidden' }}
              >
                Explore More
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={3} >

            </Grid>
          </Grid>

          {/* for web */}
          <Box
            className={styles.MCards}
            sx={{
              position: 'absolute',
              top: '100%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxWidth: '90%',
              width: '90%',
              display: 'flex',
              justifyContent: 'space-evenly',
              backgroundColor: '#fff',   // 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay for readability
              padding: '20px',
              borderRadius: '5px',
            }}
          >
            <div>
              <img
                className={styles.scoreIcons}
                src="/icon1.png"
                alt="icon1"
              />
              <span className={styles.scoreCardNumbers}>
                {`${testSeriesCount?.StudentUsersCount || "..."} +`}
              </span>
              <br />
              <span className={styles.scoreCardLabel}>
                {'Students'}
              </span>
            </div>
            <Divider orientation="vertical" flexItem style={{ backgroundColor: "black", margin: '4vh 0px 2vh 0px' }} />
            <div>
              <img
                className={styles.scoreIcons}
                src="/icon2.png"
                alt="icon2"
              />
              <span className={styles.scoreCardNumbers}>
                {`${testSeriesCount?.courseCount || "..."} +`}
              </span>
              <br />
              <span className={styles.scoreCardLabel}>
                {'Test Series'}
              </span>
            </div>
            <Divider orientation="vertical" flexItem style={{ backgroundColor: "black", margin: '4vh 0px 2vh 0px' }} />
            <div>
              <img
                className={styles.scoreIcons}
                src="/icon3.png"
                alt="icon1"
              />
              <span className={styles.scoreCardNumbers}>
                {`${testCount || "..."} +`}
              </span>
              <br />
              <span className={styles.scoreCardLabel}>
                {'Tests'}
              </span>
            </div>
            <Divider orientation="vertical" flexItem style={{ backgroundColor: "black", margin: '4vh 0px 2vh 0px' }} />

            <div>
              <img
                className={styles.scoreIcons}
                src="/icon4.png"
                alt="icon1"
              />
              <span className={styles.scoreCardNumbers}>
                {`${testSeriesCount?.questionCount || "..."} +`}
              </span>
              <br />
              <span className={styles.scoreCardLabel}>
                {'Questions'}
              </span>
            </div>
          </Box>

          {/* for mobile */}
          <Box
            className={styles.MobileCards}
            sx={{
              // position: 'absolute',
              top: '100%',
              left: '50%',
              // transform: 'translate(-50%, -50%)',
              // maxWidth: '100%',
              // width: '100%',
              // display: 'grid',
              // gridTemplateColumns: '1fr 1fr', // 2 columns
              // // gap: '10px', // Space between grid items
              // backgroundColor: '#fff',
              // padding: '20px',
              // borderRadius: '5px',
            }}
          >
            {/* First box - Students */}
            <div style={{ padding: '10px', borderRight: '1px solid #e8d0d0', borderBottom: '1px solid #e8d0d0' }}>
              <img className={styles.scoreIcons1} src="/icon1.png" alt="icon1" />
              <span className={styles.scoreCardNumbers}>
                {`${testSeriesCount?.StudentUsersCount || "..."} +`}
              </span>
              <br />
              <span className={styles.scoreCardLabel}>Students</span>
            </div>

            {/* Second box - Test Series */}
            <div style={{ padding: '10px', borderBottom: '1px solid #e8d0d0' }}>
              <img className={styles.scoreIcons2} src="/icon2.png" alt="icon2" />
              <span className={styles.scoreCardNumbers}>
                {`${testSeriesCount?.courseCount || "..."} +`}
              </span>
              <br />
              <span className={styles.scoreCardLabel}>Test Series</span>
            </div>

            {/* Third box - Tests */}
            <div style={{ padding: '10px', borderRight: '1px solid #e8d0d0' }}>
              <img className={styles.scoreIcons3} src="/icon3.png" alt="icon3" />
              <span className={styles.scoreCardNumbers}>
                {`${testCount || "..."} +`}
              </span>
              <br />
              <span className={styles.scoreCardLabel}>Tests</span>
            </div>

            {/* Fourth box - Questions */}
            <div style={{ padding: '10px' }}>
              <img className={styles.scoreIcons4} src="/icon4.png" alt="icon4" />
              <span className={styles.scoreCardNumbers}>
                {`${testSeriesCount?.questionCount || "..."} +`}
              </span>
              <br />
              <span className={styles.scoreCardLabel}>Questions</span>
            </div>
          </Box>


        </Box>

        {/* second section  */}
        <Grid container justifyContent="center" className={styles.Rectangle5}>
          <Grid item xs={11} sm={11} className={styles.DiscovertheperfectonlineprogramCover}>
            <span className={styles.Discovertheperfectonlineprogram}>
              Discover the perfect online program
            </span>
          </Grid>
          <Grid item xs={11} sm={12} className={styles.Rectangle22}>
            <Box display="flex" justifyContent="start" flexWrap="wrap">
              <IconButton disabled={true} className={styles.filterButton}>
                <FilterAltIcon className={styles.filterIcon} />
              </IconButton>
              <span className={styles.filterLabel}>Filters </span>
              <Divider orientation="vertical" className={styles.dividerX} flexItem />

              {objectives && objectives?.length > 0 && objectives.map((series, index) => (
                <Button
                  key={index}
                  variant="contained"
                  onClick={() => handleNavigation(series, index)}
                  className={styles.filterButtonFonts}
                  style={{
                    background: index == activeButtonIndex ? '#d5caf4' : '#efefef',
                    color: index == activeButtonIndex ? '#474747' : '#000000',
                    marginBottom: '10px', // Optional: space between buttons
                    marginRight: '10px', // Optional: space between buttons
                    width: 'auto', // Let the buttons shrink based on content
                    maxWidth: '100%', // Prevent buttons from overflowing
                    whiteSpace: 'normal', // Allow text to wrap inside button
                  }}
                >
                  {`${series.valueString}  ${index == activeButtonIndex ? 'X' : '  '}`}
                </Button>
              ))}
            </Box>
          </Grid>

          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={11} sm={12} md={12}>
              <Box className={styles.centerContent}
                ref={scrollRef}
                sx={{
                  display: 'flex',
                  overflowX: 'auto',
                  scrollBehavior: 'smooth',
                  '&::-webkit-scrollbar': { display: 'none' }
                }}>
                {loading ? (
                  <Box sx={{ flex: '0 0 auto', width: '100%', height: 300, m: 1, justifyContent: 'center' }}>
                    <CircularProgress
                      style={{ marginTop: '110px' }}
                    />
                  </Box>
                )
                  : (
                    testSeries && testSeries?.length > 0 ? (testSeries.map((series, index) => (
                      <Box key={index} sx={{ flex: '0 0 auto', width: 400, m: 1 }}>
                        <TestSeriesCard
                          title={series.text}
                          description={series.description}
                          image={series.image ?? '/Gemini_image_not_available.jpg'}
                          bulletPoints={series?.bulletPoints}
                          price={series?.price || '0'}
                          onLearnMore={() => handleLearnMore(series)}
                          onEnroll={() => onEnroll(series)}
                          testsCount={series?.testCount || 0}
                          testSeriesStudentsCount={series?.assingnedStudentsCount || 0}
                          studentsCount={series?.testSeriesStudentsCount || 0}
                          duration={series.duration}
                          courseId={series.courseId}
                        />
                      </Box>
                    )))
                      : (
                        <Box sx={{ flex: '0 0 auto', width: '700px', height: '300px', m: 1, justifyContent: 'center' }}>
                          <h4 style={{ marginTop: '70px', color: 'white' }}>No tests series found</h4>
                        </Box>)
                  )}
              </Box>

              {/* Test sereies cards Pagination like dots */}
              <Box display="flex" justifyContent="center" mt={2}>
                {totalPages > 1 && (
                  <Pagination
                    count={totalPages}
                    page={pageNumber}
                    onChange={handlePageChange}
                    // color="primary"
                    sx={{
                      '& .MuiPaginationItem-root': {
                        color: 'white', // Default text color
                      },
                      '& .MuiPaginationItem-root.Mui-selected': {
                        backgroundColor: 'white', // Selected background color
                        color: 'black', // Text color for the selected page
                      },
                      '& .MuiPaginationItem-ellipsis': {
                        color: 'white', // Dots color
                      },
                      '& .MuiPaginationItem-root:hover': {
                        backgroundColor: 'white', // Background color on hover
                        color: 'black', // Text color on hover
                      },
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* Third section*/}
        {/* for web */}
        <div className={styles.Group} style={{
          backgroundImage: `url(${'/banner2.png'})`,
          backgroundSize: "cover",
        }} >
          <span class={styles.Whiletheresnosecretformulaforgettingintogreatcollegeswecansupportyouinbuildingconf}>
            While there’s no secret formula for <br /> getting into great colleges, we can <br /> support you in building confidence.<br /> Learning is vital, but so are making<br />
            <span className={styles.textstyle1}>Timely Decisions,&nbsp;</span>
            <span className={styles.textstyle1}>Analyzing Progress,&nbsp; <br /> and</span>
            <span className={styles.textstyle1}> striving for </span>
            <span className={styles.textstyle1}>Perfection.</span>
          </span>
        </div>
        {/* for mobile */}
        <div className={styles.MobileGroup}
          style={{
            backgroundImage: `url(${'/banner3.PNG'})`,
            backgroundSize: "cover",
            position: "relative",
            paddingTop: '20px', // Added top padding to give space to the text
            paddingBottom: '40px',
          }} >
          <img
            className={styles.achievementImage}
            src="/Achievement.jpg"
            alt="icon1"
          />
          <div style={{ visibility: 'hidden' }}>
            <span class={styles.Whiletheresnosecretformulaforgettingintogreatcollegeswecansupportyouinbuildingconf}>
              While there’s no secret formula for <br /> getting into great colleges, we can <br /> support you in building confidence.<br /> Learning is vital, but so are making<br />
              <span className={styles.textstyle1}>Timely Decisions</span>,&nbsp;
              <span className={styles.textstyle1}>Analyzing Progress</span>,&nbsp; <br /> and
              <span className={styles.textstyle1}> striving for </span>
              <span className={styles.textstyle1}>Perfection</span>.
            </span>
          </div>
          <div style={{ marginTop: '20%' }}>
            <span class={styles.Whiletheresnosecretformulaforgettingintogreatcollegeswecansupportyouinbuildingconf}>
              While there’s no secret formula for <br /> getting into great colleges, we can <br /> support you in building confidence.<br /> Learning is vital, but so are making<br />
              <span className={styles.textstyle1}>Timely Decisions,&nbsp;</span>
              <span className={styles.textstyle1}>Analyzing Progress,&nbsp; <br /> and</span>
              <span className={styles.textstyle1}> striving for </span>
              <span className={styles.textstyle1}>Perfection.</span>
            </span>
          </div>
        </div>
        <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '20px' }}>
          <Grid item xs={11} sm={11}>
            <Typography className={styles.studentFeedbackHeadline}>
              Discover What Our Students Have To Say
            </Typography>
          </Grid>
        </Grid>
        {/* Fifth section*/}
        <Grid container spacing={2} className={styles.feedbackSection} justifyContent="center" sx={{ paddingBottom: '60px !important' }}>
          <Grid item xs={11} sm={6} sx={{ justifyItems: 'center', paddingBottom: '60px !important' }}>
            <FeedbackCard
              studentName="Bhairavi Joshi"
              feedbackText="Enrolling in this online course was the best decision I made for my college prep journey. The lessons were clear, engaging, and full of practical tips. It helped me boost my confidence, stay organized, and achieve my dream of getting into my top-choice college. Highly recommended"
              rating={5}
              studentImage="https://via.placeholder.com/150"
            />
          </Grid>
          <Grid item xs={10} sm={6} sx={{ justifyItems: 'center' }}>
            <FeedbackCard
              studentName="Prasad Shelar"
              feedbackText="This online course was a game-changer for me! The content was easy to follow, and the guidance helped me stay on track with my preparation. It boosted my confidence and made a huge difference in achieving my goals. Highly recommend it!"
              rating={5}
              studentImage="https://via.placeholder.com/150"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Home;

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useState } from 'react';
import styles from '../../styles/TestSeriesDetails.module.css';

export default function ScheduleTable({ tests }) {
    const [open, setOpen] = useState(false);
    // const [tests, setTests] = useState([]);
    // const [loading, setLoading] = useState(true); // To handle loading state

    // Fetching test schedule data from API
    // useEffect(() => {
    //     const fetchTestSchedule = async () => {
    //         try {
    //             const data = await GetCourseTestScheduleService.getCourseTestSchedule(courseId); // Fetch the data using the API
    //             console.log(data, 'recv ')
    //             setTests(data["$values"]); // Assuming the API returns an object with a `tests` array
    //             setLoading(false); // Set loading to false after data is fetched
    //         } catch (error) {
    //             console.error('Error fetching test schedule:', error);
    //             setLoading(false); // Set loading to false even if there’s an error
    //         }
    //     };

    //     fetchTestSchedule();
    // }, [courseId]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const tableHeaderStyle = {
        textAlign: 'center',
        padding: '8px',
        backgroundColor: '#f2f2f2',
        // fontWeight: 'bold',
        fontSize: '18px',
        fontFamily: 'Poppins',
        border: '1px solid #ddd',
        // font-family: Poppins;
        // font-size: 18px;
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        // whiteSpace: 'nowrap'
    };

    const tableCellStyle = {
        textAlign: 'center',
        padding: '8px',
        border: '1px solid #ddd',
        fontSize: '18px',
        fontFamily: 'Poppins',
        color: ' #686262',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    };
    const closeButtonStyle = {
        fontFamily: 'Poppins',
        fontSize: '16px',
        color: ' #686262',
        textTransform: 'none'
    };
    const tableTitleStyles = {
        fontFamily: 'Poppins',
        fontSize: '18px',
        color: ' #686262',
        textTransform: 'none'
    }
    const formatDate = (dateString) => {
        if (dateString == null) {
            return null
        }
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }).format(date); // formating to like., 7 February 2025
    };

    const formatTime = (dateString) => {
        if (dateString == null) {
            return null
        }
        const date = new Date(dateString);

        // adding 5.5 hours
        date.setHours(date.getHours() + 5);
        date.setMinutes(date.getMinutes() + 30);

        return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }); // formating to like, 10:30 AM
    };
    console.log(tests, 'trust')
    return (
        <div>
            {
                tests.length > 0 ?
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th style={{ ...tableHeaderStyle, width: '25%' }}>Code</th>
                                <th style={{ ...tableHeaderStyle, width: '35%' }}>Test Name</th>
                                <th style={{ ...tableHeaderStyle, width: '20%' }}>Date</th>
                                <th style={{ ...tableHeaderStyle, width: '20%' }}>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                // loading ? (
                                //     <tr>
                                //         <td colSpan="4" style={{ textAlign: 'center', padding: '20px' }}>
                                //             <CircularProgress />
                                //         </td>
                                //     </tr>
                                // ) : (

                                // )
                                tests.slice(0, 5).map((test, index) => (
                                    <tr key={index}>
                                        <td style={tableCellStyle}>{test.code}</td>
                                        <td style={tableCellStyle}>{test.testName}</td>
                                        <td style={tableCellStyle}>{formatDate(test.startDate)}</td>
                                        <td style={tableCellStyle}>{formatTime(test.startDate)}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table> :
                    <Typography sx={{
                        fontFamily: 'Poppins',
                        fontSize: '18px',
                        color: '#686262',
                        textAlign: 'center',
                    }}>{'Schedule not found!'}</Typography>
            }

            {tests.length > 5 && <Button
                variant="outlined"
                // color="primary"
                onClick={handleClickOpen}
                className={styles.Rectangle39}
            // style={{ marginTop: '16px' }}
            >
                Load More
            </Button>}

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md" // You can adjust this value for more or less size
            >
                <DialogTitle sx={tableTitleStyles}>Test Series Schedules</DialogTitle>
                <DialogContent>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th style={{ ...tableHeaderStyle, width: '15%' }}>Code</th>
                                <th style={{ ...tableHeaderStyle, width: '45%' }}>Test Name</th>
                                <th style={{ ...tableHeaderStyle, width: '20%' }}>Date</th>
                                <th style={{ ...tableHeaderStyle, width: '20%' }}>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tests.map((test, index) => (
                                <tr key={index}>
                                    <td style={tableCellStyle}>{test.code}</td>
                                    <td style={tableCellStyle}>{test.testName}</td>
                                    <td style={tableCellStyle}>{formatDate(test.startDate)}</td>
                                    <td style={tableCellStyle}>{formatTime(test.startDate)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        sx={closeButtonStyle}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

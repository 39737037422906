
import { useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoginContext } from "./contexts/LoginContext";


const ProtectedRoute = (props) => {
    const { Component } = props
    const navigate = useNavigate();
    const { setUserName, UserName, setEmailC, setProviderId, setUserId, setUserType, isDefaultSplash } =
        useContext(LoginContext);
    const { id } = useParams();
    const location = useLocation();
    console.log(id, "idis");
    useEffect(() => {
        let login = sessionStorage.getItem('login');
        console.log(login);
        let loginAuth = sessionStorage.getItem('PersonalInfo')
        window.onbeforeunload = function () {
            sessionStorage.clear();
        };
        console.log(isDefaultSplash, 'isDefaultSplash1', isDefaultSplash == false)
        if (!login && location.pathname !== '/' && location.pathname !== '/medineer') {
            if (isDefaultSplash == false) {
                navigate('/')   //(/medineer)
            } else {
                navigate('/')
            }
        }

        // if(loginAuth){
        //     // setProviderId(loginAuth.providerId)
        //     // setUserId(loginAuth.userId)
        //     // setUserName(loginAuth.fullName)
        //     // setEmailC(loginAuth.email)
        //     navigate('/Home')
        // }
        else {
            if (id) {
                console.log('navigate to provider signin')
                navigate('/signIn/' + id)
            }
        }
    }, [])

    return (
        <div>
            <Component />
        </div>
    )
}
export default ProtectedRoute;
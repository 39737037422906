
import { Box, Card, CircularProgress, Grid, Tooltip, makeStyles } from "@material-ui/core";
import { PlayArrow, Visibility } from "@material-ui/icons";
import { AnalyticsRounded } from "@mui/icons-material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Divider, Drawer, IconButton, InputBase, Paper, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from '@mui/material';
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { configUrl } from "../config";
import { LoginContext } from "../contexts/LoginContext";
import useGetAttempCount from "../resultHooks/useGetAttempCount";
import TargetAudienceOfTest from '../services/TargetAudienceOfTest';
import styles from '../styles/testviewer.module.css';
import useGetAssignedTest from "../testsHooks/useGetAssignedTests";
import UseGetAttemptDetails from "../testsHooks/useGetAttemptDetails";
import Analytics from "./Analytics";
import CustomModal from "./CustomModal/CustomModal";
import CustomModalNew from "./CustomModal/CustomModalNew";
import TestPlayerNew from "./Player/TestPlayerNew";
import EvaluationQuestionWrapper from "./Questions/EvaluationQuestionWrapper";
import WarningMessege from "./WarningMessege";

const useStyles = makeStyles((theme) => ({
  trialButton: {
    backgroundColor: '#ce93d8',  // Light Purple
    color: 'white'
  },
  enabledButton: {
    backgroundColor: '#e0e0e0',   // '#cfcfcf',
    color: 'white'
  },
  defaultButton: {
    backgroundColor: '#9bcaf1',
  },
  view: {
    backgroundColor: 'transperant',
    borderRadius: '10px',
    color: '#6ae6b7',
    border: 'none',
    whiteSpace: 'nowrap',
    // padding: '4px 8px'
  },
  disablebtn: {
    backgroundColor: '#transperant',
    borderRadius: '50%',
    color: '#cfcfcf',
    border: 'none',
    whiteSpace: 'nowrap',
    // padding: 3
  },
  analyticsbtn: {
    backgroundColor: '#transperant',
    borderRadius: 10,
    color: '#F7B2A0',
    border: 'none',
    whiteSpace: 'nowrap',
    // padding: 3,
    margin: '0 6px 0 6px'
  },
  disabledanalyticsbtn: {
    backgroundColor: 'transperant',
    borderRadius: 10,
    color: '#cfcfcf',
    border: 'none',
    whiteSpace: 'nowrap',
    // padding: 3,
    margin: '0 6px 0 6px'
  },
  tooltip: {
    fontSize: "1em",
  },
}));

function TestViewer(props) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const classes = useStyles();
  const { providerId, contentCuratorIds, user, userPrivileges, emailC, status, alertFlag, setAlertFlag, assessment, testSeriesObj, setShowTOC, UserId, testSeriesCardStatus, topicId, setTestSeriesObj, setShowModalClose } = useContext(LoginContext);
  //const { data: userDetails, mutate: refetch1 } = useGetUserByUserName();
  // let test = JSON.parse(localStorage.getItem("AttemptedTest"))  //TempCommit
  let resumeTestId = localStorage.getItem(`${UserId}TestId`)
  let localUserId = localStorage.getItem(`${UserId}UserId`)
  const attempt = useGetAttempCount();
  console.log(UserId, 'userDetails123')
  const [isTggleOpen, setIsToggleOpen] = useState((testSeriesCardStatus == "COMPLETED" || status == "COMPLETED") ? false : true);
  const [selectedTargetAudience, setSelectedTargetAudience] = useState(null);
  const [showTest, setShowTest] = React.useState(false);
  const [testIndex, setTestIndex] = React.useState(0);
  const [testPlayerModal, setTestPlayerModal] = React.useState(null);
  const [evaluationModel, setEvaluationModel] = React.useState(null);
  const [courseId, setCourseId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const [activeButtonIndex, setActiveButtonIndex] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchToggle, setSearchToggle] = useState(true);
  const { data: testDetails, isLoading, refetch } = useGetAssignedTest(UserId ?? null, isTggleOpen == true ? "ALL" : "COMPLETED", isTggleOpen, selectedTargetAudience, searchValue);
  const toggleMobileFilter = () => {
    setShowMobileFilter(!showMobileFilter);
  };

  const [objectives, setObjectives] = useState([
  ]);
  const targetAudienceOfTest = async () => {
    const targetAudienceResp = await TargetAudienceOfTest.TargetAudienceOfTest(assessment == true ? 0 : topicId == null ? 0 : topicId, providerId, contentCuratorIds)
    setObjectives(targetAudienceResp);
  }
  useEffect(() => {
    targetAudienceOfTest();
  }, [assessment])
  console.log("objecTtves", objectives);

  // React.useEffect(() => {
  //   refetch1(emailC);
  // }, []);
  const getSeriesdta = async () => {
    setLoading(true);
    console.log(topicId, 'topicID')
    try {
      let getSeries = await axios.get(`${configUrl.AssessmentServer}/api/Tests/getCourseAssignedTests/${UserId}/${isTggleOpen == true ? "ALL" : "COMPLETED"}/${topicId}/${selectedTargetAudience}?filter=${isTggleOpen || false}&testName=${searchValue == '' ? null : searchValue}`)
      setTestSeriesObj(getSeries.data["$values"]);
      console.log("attemp", getSeries?.data["$values"][0]?.courseId, getSeries.data["$values"]);
      let id = (getSeries?.data["$values"] && getSeries?.data["$values"]?.length > 0) ? getSeries?.data["$values"][0]?.courseId : null;
      setCourseId(id);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }
  React.useEffect(() => {
    refetch();
    getSeriesdta();
    document.exitFullscreen()
  }, [testPlayerModal, selectedTargetAudience])
  useEffect(() => {
    if (!isLoading) {
      if (assessment == true) {
        refetch();
      } else {
        getSeriesdta();
      }

    }
  }, [isTggleOpen, searchToggle]);

  const getAttempts = async (id) => {
    let data = await attempt.mutateAsync({
      userId: user,
      testId: id,
    });

    console.log("data", data);
    return data;
  };
  const clearCatche = (i, id, a, testName) => {
    console.log(i, id, a, testName, 'propsData1');
    localStorage.removeItem(`${UserId}UserId`);
    localStorage.removeItem(`${UserId}currentQuestionIndex`);
    localStorage.removeItem(`${UserId}TestId`);
    localStorage.removeItem(`${UserId}remainingTime`);
    localStorage.removeItem(`${UserId}AttemptedTest`);
    // localStorage.clear();
    hideEvaluationModel();
    showTestModelNew(i, id, a, testName, true);
  };
  const showTestExistAlert = (i, id, a, testName) => {
    setEvaluationModel(
      <CustomModal
        modal={true}
        setModal={hideEvaluationModel}
        content={
          <WarningMessege
            message={t("onGoingTestAlert")}
          />
        }
        showOk={true}
        okButtonMessage={'Ok'}
        showCancel={true}
        cancelButtonMessage={'Cancel'}
        onOkClick={() =>
          clearCatche(i, id, a, testName)
        }
        onCancelClick={hideEvaluationModel}
        title={`Warning`}
        maxWidth="sm"
        hideTitle={true}
        fullWidth={false}
      />
    );
  };
  // start
  const showTestModelNew = async (i, id, a, testName, flag, testCourseId) => {
    setShowModalClose(false);
    let online = navigator.onLine;
    let test;

    let rtest;
    // hide TOC modal on fresh click on
    setShowTOC(false);
    setAlertFlag(false)
    // this is for check internet connection availability.
    if (online == false) {
      setEvaluationModel(
        <CustomModal
          modal={true}
          setModal={hideEvaluationModel}
          content={
            <WarningMessege
              message={"You are not connected with internet. You can resume your test only after internet is connected."}
            />
          }
          showOk={false}
          okButtonMessage={'Ok'}
          showCancel={true}
          cancelButtonMessage={'Ok'}
          onOkClick={() =>
            clearCatche(i, id, a, testName)
          }
          onCancelClick={hideEvaluationModel}
          title={`Warning`}
          maxWidth="sm"
          hideTitle={true}
          fullWidth={false}
        />
      );
      return
    }
    // this is for checking existing test is available or not (showing options yes or no and clear same user existing test data on click of yes option.)
    if (flag == false && UserId != null && UserId == localUserId) {
      if (resumeTestId != null && resumeTestId != id) {
        console.log('is here1')
        showTestExistAlert(i, id, a, testName)
        return
      }
    }

    const assessmentDetail = await UseGetAttemptDetails(UserId, id, a)
    // this is for data not found
    if (assessmentDetail?.response?.status == 400) {
      setEvaluationModel(
        <CustomModal
          modal={true}
          setModal={hideEvaluationModel}
          content={
            <WarningMessege
              message={assessmentDetail?.response?.data}
            />
          }
          showOk={false}
          okButtonMessage={'Ok'}
          showCancel={true}
          cancelButtonMessage={'Ok'}
          onOkClick={() =>
            hideEvaluationModel
          }
          onCancelClick={hideEvaluationModel}
          title={`Warning`}
          maxWidth="sm"
          hideTitle={true}
          fullWidth={false}
        />)
      return
    }

    test = JSON.parse(localStorage.getItem(`${UserId}AttemptedTest`))
    console.log(test, 'getting localed test')

    // const targetStartDateTime = assessmentDetail?.testDetails?.testStartDate ? toLocal(assessmentDetail?.testDetails.testStartDate) : null;
    // const targetEndDateTime = assessmentDetail?.testDetails?.testEndDate ? toLocal(assessmentDetail?.testDetails.testEndDate) : null;
    // const currentDateTime = new Date();

    if (test == null || resumeTestId == null || test.testId != resumeTestId || test.testId != id) {
      test = JSON.parse(assessmentDetail?.testDetails?.attemptDetails)
      console.log('is here11', test)
      localStorage.setItem(`${UserId}AttemptedTest`, JSON.stringify(test));
      localStorage.setItem(`${UserId}UserId`, assessmentDetail?.testDetails?.userId)
    }

    // if (resumeTestId == null || test.testId != resumeTestId) {
    //   try {
    //     // added this for if test is not expired or scheduled later in this case 
    //     if ((targetStartDateTime <= currentDateTime &&
    //       targetEndDateTime >= currentDateTime) ||
    //       (assessmentDetail?.testDetails?.testStartDate == null &&
    //         assessmentDetail?.testDetails?.testEndDate == null)) {

    //       localStorage.setItem(`${UserId}AttemptedTest`, JSON.stringify(test));
    //       localStorage.setItem(`${UserId}UserId`, assessmentDetail?.testDetails?.userId)
    //       localStorage.setItem(`${UserId}TestId`, test.testId)
    //     } else {
    //       console.log('not stored');
    //     }

    //   } catch (e) {
    //     console.error(e, 'localError')
    //     setEvaluationModel(
    //       <CustomModal
    //         modal={true}
    //         setModal={hideEvaluationModel}
    //         content={
    //           <WarningMessege
    //             message={"Failed to store test data locally."}
    //           />
    //         }
    //         showOk={false}
    //         okButtonMessage={'Ok'}
    //         showCancel={true}
    //         cancelButtonMessage={'Ok'}
    //         onOkClick={() =>
    //           clearCatche(i, id, a, testName)
    //         }
    //         onCancelClick={hideEvaluationModel}
    //         title={`Warning`}
    //         maxWidth="sm"
    //         hideTitle={true}
    //         fullWidth={false}
    //       />)
    //   }
    // }

    //this is Test player including start screen
    rtest = test.pools["$values"].map((x, i) => {
      x.showTOCBtn = true;
      x.showTimer = true;
      x.showSubmit = true;
      return x;
    });
    console.log(test, 'localTest')
    setTestPlayerModal(
      <CustomModalNew
        modal={true}
        setModal={hideTestModal}
        content={
          <TestPlayerNew
            tests={test}  //JSON.parse(assessmentDetail?.testDetails?.attemptDetails)}
            testAttemptDetails={assessmentDetail?.testDetails}
            questionObj={JSON.parse(assessmentDetail?.questionObj)}
            testId={id}
            userId={UserId ?? null}
            attempt={a}
            isCourse={testCourseId ? true : false}
            courseId={testCourseId ?? null}
            showTimer={rtest && rtest[0].showTimer}
            showSubmit={rtest && rtest[0].showSubmit}
            onFinish={hideTestModal}
            preview={false}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={hideTestModal}
        showAlertAllowed={true}
        title={testName}
        maxWidth="lg"
        fullWidth={false}
        hideTitle={true}
        fullScreen={true}
        shouldCloseOnOverlayClick={false}
        hideFooter={true}

      />
    );
  };
  // end


  const hideTestModal = (event, reason) => {
    if (reason) console.log("reason", reason);
    setTestPlayerModal(null);
  };
  const showEvaluationModel = (id, testName) => {
    console.log('Click2')
    setEvaluationModel(
      <CustomModal
        modal={true}
        setModal={hideEvaluationModel}
        content={
          <EvaluationQuestionWrapper
            testId={id}
            userId={UserId ?? null}
            isCourse={false}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={hideEvaluationModel}
        title={`${testName} ${t('evaluation')}`}
        maxWidth="lg"
        hideTitle={true}
        fullScreen={true}
        fullWidth={false}
      />
    );
  };
  const showAnalytics = async (lOid, testName) => {
    console.log('Click3')
    setEvaluationModel(
      <CustomModal
        modal={true}
        setModal={hideEvaluationModel}
        content={
          <Analytics
            lOid={lOid}
            studentId={UserId ?? null}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={hideEvaluationModel}
        title={`${testName}  ${t('analytics')}`}
        maxWidth="lg"
        hideTitle={true}
        fullScreen={true}
        fullWidth={false}
      />
    )
  }

  const hideEvaluationModel = (event, reason) => {
    if (reason) console.log("reason", reason);
    setEvaluationModel(null);
  };

  const isPrivilege = (x) => {
    console.log(userPrivileges, "userPrivileges");
    if (userPrivileges?.Privileges?.Test.indexOf(x) !== -1) {
      return true;
    }
    else if (userPrivileges?.Privileges?.LearnerSite.indexOf(x) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

  function getDiff() {
    const currentDate = new Date(); // Current date in local timezone
    const currentUtcDate = new Date(currentDate.toUTCString());

    const timezoneOffsetLocal = currentDate.getTimezoneOffset(); // Offset in minutes
    const timezoneOffsetUTC = currentUtcDate.getTimezoneOffset(); // Offset in minutes

    const offsetHoursLocal = Math.abs(timezoneOffsetLocal / 60);
    const offsetMinutesLocal = Math.abs(timezoneOffsetLocal % 60);

    const offsetHoursUTC = Math.abs(timezoneOffsetUTC / 60);
    const offsetMinutesUTC = Math.abs(timezoneOffsetUTC % 60);

    console.log(`UTC offset: ${offsetHoursUTC} hours ${offsetMinutesUTC} minutes`);
    console.log(`Local timezone offset: ${offsetHoursLocal} hours ${offsetMinutesLocal} minutes`);
    return offsetHoursLocal;
  }
  const toLocal = (UTCTime) => {
    // converting into local time start
    const utcDateTime = new Date(UTCTime);
    let diff = getDiff();
    console.log(diff, 'inDiff')
    const istOffset = diff * 60 * 60 * 1000; // IST is UTC+5.5 hours
    const istDateTime = new Date(utcDateTime.getTime() + istOffset);
    return istDateTime
  }

  const toggleButtonStyles = (selected) => ({
    fontSize: '13px',
    fontFamily: 'Poppins',
    borderRadius: selected ? '87px' : '0',
    padding: '8px 11px',
    textTransform: 'capitalize',
    border: 'none',
    backgroundColor: selected ? "#fff" : "transparent",
    color: selected
      ? "#012248"
      : "#a0a0a0",
    cursor: 'pointer',
    boxShadow: selected ? '0 2px 2px 0 rgba(0, 0, 0, 0.1)' : '',
    transition: 'background-color 0.3s ease, color 0.3s ease',
    '&:hover': {
      backgroundColor: theme.palette.heading.main,
      color: theme.palette.drawerBackground.main,
      transform: 'scale(1.05)',
    },
  });

  const toggleGroupStyles = {
    display: 'inline-flex',
    borderRadius: '87px',
    borderColor: "black",
    padding: '5px',
    marginRight: '9px',
    marginBottom: '10px',
    backgroundColor: '#f2e7e7',
    boxShadow: 'inset 0 1px 2px 0 rgba(0, 0, 0, 0.1)'
  };


  const handleToggle = (event, newSubscription) => {
    if (newSubscription !== null) {
      setIsToggleOpen(newSubscription === "completed");
    }
  };


  const handleNavigation = (series, index) => {
    setActiveButtonIndex(activeButtonIndex == index ? null : index)
    if (selectedTargetAudience == series?.id) {
      setSelectedTargetAudience(null)
    } else {
      setSelectedTargetAudience(series.id)
    }
  }
  console.log(testDetails, 'noss', testSeriesObj)


  // Handle input change
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  // Handle search action
  const handleSearch = () => {
    setSearchToggle(searchToggle == true ? false : true);
    console.log('Searching for:', searchValue, searchToggle);
  };

  // Handle form submission
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div>
      {/* <GridContainer>
        <GridItem xs={12}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}> */}
      {/* TODO */}
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <h4 className={styles.notificationHead} style={{ color: '#012248' }}>{t('myAssignedTests')}</h4>
        </Grid>

        <Grid item xs={12} sm={12} md={6} style={{ textAlign: 'right' }}>
          {/* Mobile Filter */}
          <div className={styles.mobileFilter}>
            <IconButton className={styles.filterButton} onClick={toggleMobileFilter}>
              <FilterAltIcon className={styles.filterIcon} />
            </IconButton>

            {/* Mobile filter options using Drawer (side panel) */}
            <Drawer
              anchor="bottom"
              open={showMobileFilter}
              onClose={toggleMobileFilter}
              sx={{ width: '100%', maxWidth: '100%' }}
            >
              <Box className={styles.mobileFilterDrawer} sx={{ padding: 2 }}>
                <Typography variant="h6" className={styles.filterLabelMobile}>
                  Filters
                </Typography>
                <Divider sx={{ marginBottom: 2 }} />
                {objectives && objectives?.length > 0 && objectives.map((series, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    onClick={() => handleNavigation(series, index)}
                    className={styles.filterButtonFonts}
                    style={{
                      background: index === activeButtonIndex ? '#f2e7e7' : '#fff',
                      color: index === activeButtonIndex ? '#474747' : '#000000',
                      marginBottom: '10px',
                      marginRight: '10px',
                      width: '100%',
                      whiteSpace: 'normal',
                    }}
                  >
                    {series.valueString}
                  </Button>
                ))}
              </Box>
            </Drawer>
          </div>
          <ToggleButtonGroup
            value={isTggleOpen ? "completed" : "pending"}
            exclusive
            onChange={handleToggle}
            aria-label="Subscription Toggle"
            style={toggleGroupStyles}
          >
            <ToggleButton value="completed"
              disabled={isLoading == true || loading == true}
              aria-label="Completed"
              style={toggleButtonStyles(isTggleOpen)} >
              {'Pending'}
            </ToggleButton>
            <ToggleButton
              value="pending"
              disabled={isLoading == true || loading == true}
              aria-label="Pending"
              style={toggleButtonStyles(!isTggleOpen)} >
              {'Completed'}
            </ToggleButton>
          </ToggleButtonGroup>

        </Grid>
        <Grid item xs={12} sm={12} md={8} className={styles.Rectangle22}>
          <Box style={{ display: 'flex' }} >
            {/* justifyContent="start" flexWrap="wrap"> */}
            <IconButton disabled={true} className={styles.filterButton}>
              <FilterAltIcon className={styles.filterIcon} />
            </IconButton>
            {/* <span className={styles.filterLabel}>Filters </span> */}
            <Divider orientation="vertical" className={styles.dividerX} flexItem />
            <div style={{ flexFlow: 'wrap' }}>
              {
                // assessment == true ? testDetails && testDetails.length > 0 : testSeriesObj && testSeriesObj.length > 0 &&
                objectives && objectives?.length > 0 && objectives.map((series, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    onClick={() => handleNavigation(series, index)}
                    className={styles.filterButtonFonts}
                    style={{
                      background: index == activeButtonIndex ? '#f2e7e7' : '#fff',
                      color: index == activeButtonIndex ? '#474747' : '#000000',
                      marginBottom: '10px', // Optional: space between buttons
                      marginRight: '10px', // Optional: space between buttons
                      width: 'auto', // Let the buttons shrink based on content
                      maxWidth: '100%', // Prevent buttons from overflowing
                      whiteSpace: 'normal', // Allow text to wrap inside button
                    }}
                  >
                    {`${series.valueString}  ${index == activeButtonIndex ? 'X' : '  '}`}
                  </Button>
                ))}
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={styles.Rectangle23}>
          <Paper
            className={styles.searchBar}
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', float: 'right', marginInlineEnd: '0' }}
            onSubmit={(e) => e.preventDefault()}
          >

            <InputBase
              sx={{ ml: 1, flex: 1, fontSize: 'initial' }}
              placeholder="Search"
              value={searchValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
            />
            <IconButton type="button" aria-label="search" onClick={handleSearch}>
              <SearchIcon className={styles.searchIcon} />
            </IconButton>
          </Paper>
        </Grid>

        {
          (isLoading == true || loading == true) ?
            <Grid item xs={12} sm={12} md={10}>
              <Box className={styles.Loader}>
                <CircularProgress />
                <h4 >{t('loading')}</h4>
              </Box>
            </Grid>
            :
            assessment == true ?
              !testDetails || testDetails && testDetails.filter((x) => {
                let dateDiff = new Date() - new Date(x.schedule);
                return dateDiff > 0 && x?.subscriptionStatus === 3;
              }).length <= 0 ?
                <Grid item xs={12} sm={12} md={10}>
                  <h4 className={styles.notificationTextTitle}>
                    {isTggleOpen ? "It appears you haven't purchased any assessments or have already completed them." :
                      "You haven't completed any assessments yet. Once you finish an assessment, you'll be able to view it here."}
                  </h4>
                </Grid> :
                testDetails && testDetails.length > 0 && testDetails
                  .filter((x) => {
                    let dateDiff = new Date() - new Date(x.schedule);
                    console.log("dateDiff", dateDiff);
                    return dateDiff > 0 && x?.subscriptionStatus === 3;
                  }).map((x, i) => (
                    <Grid item xs={12} sm={12} md={6}>
                      <Card style={UserId == localUserId && x.testId == resumeTestId ? { background: 'linear-gradient(to right, rgba(255,0,0,0), #bdf2f8)' } : { backgroundColor: '#ffff' }} className={styles.card}>
                        <div style={x.result == 3 ? { color: theme.palette.heading.main, background: '#fd930a19 10%' } : null} className={x.showResult === 2 ? (x.result == undefined ? styles.floatingHeadWait : x.result == 3 ? styles.floatingHeadNA : x.result === 1 ? styles.floatingHeadFail : styles.floatingHeadPass) : styles.floatingHeadWait}>
                          {console.log("x.showResult:", x.showResult, "x.result:", x.result)}
                          {x.showResult === 2 ? (x.result == undefined ? t("toDo") : x.result == 3 ? t("na") : x.result === 1 ? t('failed') : t('passed')) : t('awaited')}
                          <br />
                          {x.showResult == 2 ? x.actualScore ?? '--' : '--'}/{x.totalScore ?? 0}

                        </div>

                        <h4 className={styles.notificationTextTitle}>{isMobile ? x.name?.substr(0, 13) : x.name}<span style={{ float: 'right', marginInline: '1px' }}>
                          {
                            isPrivilege("PlayTest") == true ?
                              <Tooltip classes={{ tooltip: classes.tooltip }} title={t("play")}>
                                <button
                                  className={((x.maxAttempts <= x.attemptNumber ?? 0) || (x?.subscriptionStatus == 1)) ? classes.enabledButton : classes.defaultButton}
                                  style={{ borderRadius: '50%', padding: '2px 7px', color: 'white', border: 'none', margin: '0px 6px 0px 0px', whiteSpace: 'nowrap' }}
                                  onClick={() => {
                                    setTestIndex(i);
                                    showTestModelNew(
                                      i,
                                      x.testId,
                                      (x.attemptNumber ?? 0) + 1,
                                      x.name, false
                                    );
                                  }}
                                  disabled={(x.maxAttempts <= x.attemptNumber ?? 0) || (x?.subscriptionStatus == 1)}>
                                  {x.maxAttempts <= x.attemptNumber ?? 0
                                    ? <PlayArrow />
                                    : <PlayArrow />}
                                </button>
                              </Tooltip>
                              : ""
                          }
                          {/* horizontal divider */}
                          {/* <Divider orientation="vertical" style={{ margin: '8px 0' }} /> */}
                          <span style={{ color: "#a7a2a2", fontFamily: 'monospace' }}>|</span>
                          {
                            isPrivilege("ViewAnswerScript") == true ?
                              <Tooltip classes={{ tooltip: classes.tooltip }} title={t("viewanswerscript")}>
                                <buton
                                  className={x.attemptNumber <= 0 || x.showResult == 1 ? classes.disablebtn : classes.view}
                                  onClick={x.attemptNumber <= 0 || x.showResult == 1 ? '' : () => showEvaluationModel(x.testId, x.name)}
                                  style={{ margin: '0px 6px 0px 6px' }}
                                >
                                  <Visibility style={{ fontSize: 'xx-large' }} />
                                </buton>
                              </Tooltip>
                              : ""
                          }
                          {/* horizontal divider */}
                          <span style={{ color: "#a7a2a2", fontFamily: 'monospace' }}>|</span>
                          <Tooltip classes={{ tooltip: classes.tooltip }} title={t("analytics")}>
                            <buton
                              className={x.attemptNumber <= 0 || x.showResult == 1 ? classes.disabledanalyticsbtn : classes.analyticsbtn}
                              disabled={x.attemptNumber <= 0}
                              onClick={x.attemptNumber <= 0 || x.showResult == 1 ? '' : () => showAnalytics(x.testId, x.name)}
                            >
                              <AnalyticsRounded style={{ fontSize: 'xx-large' }} />
                            </buton>
                          </Tooltip>
                        </span></h4>
                        <h5 className={styles.notificationText}><small> {t("lastAttempt")} - {!x.attemptedOn || x.attemptedOn === null || x.attemptedOn === undefined ? <span > {t("YetToAttempt")}</span> : <Moment style={{ fontWeight: '100' }} format="DD-MMM-YYYY">{toLocal(x.attemptedOn)}</Moment>},&nbsp; <span>{t("attempts")} - <span style={{ fontWeight: '100' }}> {x.attemptNumber ?? 0}/{x.maxAttempts ?? 1}</span></span> </small></h5>

                      </Card>
                    </Grid>

                  )) :
              testSeriesObj.length > 0 ?
                testSeriesObj && testSeriesObj
                  .filter((x) => {
                    let dateDiff = new Date() - new Date(x.schedule);
                    console.log("dateDiff", dateDiff);
                    return dateDiff > 0;
                  }).map((x, i) => (
                    <Grid item xs={12} sm={12} md={6}>
                      <Card style={UserId == localUserId && x.testId == resumeTestId ? { background: 'linear-gradient(to right, rgba(255,0,0,0), #bdf2f8)' } : { backgroundColor: '#ffff' }} className={styles.card}>
                        <div style={x.result == 3 ? { color: theme.palette.heading.main, background: '#fd930a19 10%' } : null} className={x.showResult === 2 ? (x.result == undefined ? styles.floatingHeadWait : x.result == 3 ? styles.floatingHeadNA : x.result === 1 ? styles.floatingHeadFail : styles.floatingHeadPass) : styles.floatingHeadWait}>
                          {/* {x.result == undefined ? 'ToDo' : x.result === 1 ? t('failed') : t('passed')} */}
                          {x.showResult === 2 ? (x.result == undefined ? t("toDo") : x.result == 3 ? t("na") : x.result === 1 ? t('failed') : t('passed')) : t('awaited')}
                          <br />
                          {x.showResult == 2 ? x.actualScore ?? '--' : '--'}/{x.totalScore ?? 0}

                        </div>
                        <h4 className={styles.notificationTextTitle}>{isMobile ? x.name?.substr(0, 13) : x.name}<span style={{ float: 'right', marginInline: '1px' }}>
                          {
                            isPrivilege("PlayTest") == true ?
                              <Tooltip classes={{ tooltip: classes.tooltip }} title={t("play")}>
                                <button
                                  className={(!(x.maxAttempts <= x.attemptNumber ?? 0) && (x?.userSubscriptionStatus != 2 ? (x?.testSubscriptionStatus == 2 ? true : false) : true)) ? ((x?.testSubscriptionStatus == 2 && x?.userSubscriptionStatus != 2) ? classes.trialButton : classes.defaultButton) : classes.enabledButton}
                                  style={{ borderRadius: '50%', padding: '2px 7px', color: 'white', border: 'none', margin: '0px 6px 0px 0px', whiteSpace: 'nowrap' }}
                                  onClick={() => {
                                    setTestIndex(i);
                                    showTestModelNew(
                                      i,
                                      x.testId,
                                      (x.attemptNumber ?? 0) + 1,
                                      x.name, false, courseId
                                    );
                                  }}
                                  disabled={(x.maxAttempts <= x.attemptNumber ?? 0) || (x?.userSubscriptionStatus != 2 && x?.testSubscriptionStatus != 2)}>
                                  {x.maxAttempts <= x.attemptNumber ?? 0
                                    ? <PlayArrow />
                                    : <PlayArrow />}
                                </button>
                              </Tooltip>
                              : ""
                          }
                          <span style={{ color: "#a7a2a2", fontFamily: 'monospace' }}>|</span>
                          {
                            isPrivilege("ViewAnswerScript") == true ?
                              <Tooltip classes={{ tooltip: classes.tooltip }} title={t("viewanswerscript")}>
                                <buton
                                  className={x.attemptNumber <= 0 || x.showResult == 1 ? classes.disablebtn : classes.view}
                                  onClick={x.attemptNumber <= 0 || x.showResult == 1 ? '' : () => showEvaluationModel(x.testId, x.name)}
                                  style={{ margin: '0px 6px 0px 6px' }}
                                >
                                  <Visibility style={{ fontSize: 'xx-large' }} />
                                </buton>
                              </Tooltip>
                              : ""

                          }
                          <span style={{ color: "#a7a2a2", fontFamily: 'monospace' }}>|</span>
                          <Tooltip classes={{ tooltip: classes.tooltip }} title={t("analytics")}>
                            <buton
                              className={x.attemptNumber <= 0 || x.showResult == 1 ? classes.disabledanalyticsbtn : classes.analyticsbtn}
                              disabled={x.attemptNumber <= 0}
                              onClick={x.attemptNumber <= 0 || x.showResult == 1 ? '' : () => showAnalytics(x.testId, x.name)}
                            >
                              <AnalyticsRounded style={{ fontSize: 'xx-large' }} />
                            </buton>
                          </Tooltip>
                        </span></h4>
                        <h5 className={styles.notificationText}><small> {t("lastAttempt")} - {!x.attemptedOn || x.attemptedOn === null || x.attemptedOn === undefined ? <span style={{ color: '#594e4e' }}> {t("YetToAttempt")}</span> : <Moment style={{ fontWeight: '100' }} format="DD-MMM-YYYY">{toLocal(x.attemptedOn)}</Moment>},&nbsp; <span>{t("attempts")} - <span style={{ fontWeight: '100' }}> {x.attemptNumber ?? 0}/{x.maxAttempts ?? 1}</span></span> </small></h5>

                      </Card>
                    </Grid>
                  )) :
                <Grid item xs={12} sm={12} md={10}>
                  {/*
                  <Card className={styles.card}>
                    <div className={styles.floatingHeadWait}>
                       <Moment format="MMM">
                        {new Date()}
                      </Moment>
                      <br />
                      <Moment format="DD">
                        {new Date()}
                      </Moment>
                    </div>
                       */}
                  {/* <h4 className={styles.notificationTextTitle}>{t('TestsNotFound')}</h4> */}
                  <h4 className={styles.notificationTextTitle}>
                    {isTggleOpen ? "It appears you haven't purchased any assessments or have already completed them." :
                      "You haven't completed any assessments yet. Once you finish an assessment, you'll be able to view it here."}

                  </h4>

                  {/* </Card> */}
                </Grid>
        }
      </Grid>
      {/* </GridItem>
          </GridContainer> */}

      {testPlayerModal}
      {evaluationModel}
      {/* </GridItem>
      </GridContainer > */}
    </div >
  );
}

export default TestViewer;
